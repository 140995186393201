import Vue from "vue";
import AccountService from "./AccountService";

export default {
  uploadThumbnailsToS3ForVimeoEmbedLink(vimeoEmbedUrl) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/upload/thumbnails";
    let thumbnailUpload = {};
    let options = AccountService.getSessionHeaders();

    thumbnailUpload.vimeoEmbedUrl = vimeoEmbedUrl;

    return Vue.axios.post(apiMethodUrl, thumbnailUpload, options);
  }
};
