<template>
  <div class="videoForm" v-if="isLoaded">
    <form v-on:submit.prevent="saveChanges">
      <div class="row">
        <label for="trial_workout">Trial:</label>
        <div class="form-check">
          <input
            type="radio"
            class="form-check-input"
            name="trial_workout"
            id="trial_workout_yes"
            value="1"
            v-model="video.trial"
          />
          <label for="featured_media_type_video" class="form-check-label"
            >Yes</label
          >
        </div>
        <div class="form-check" style="margin-left: 15px">
          <input
            type="radio"
            class="form-check-input"
            name="trial_workout"
            id="trial_workout_no"
            value="0"
            v-model="video.trial"
          />
          <label for="featured_media_type_image" class="form-check-label"
            >No</label
          >
        </div>
      </div>


      <div class="form-group">
        <label for="title">Instructor:</label>
        <select
          class="form-control"
          name="workout_type"
          v-model="video.instructor"
        >
          <option
            v-for="instructor in instructors"
            v-bind:value="instructor.id"
            v-bind:key="instructor.id"
          >
            {{ instructor.fullname }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="title">Title:</label>
        <input
          type="text"
          v-model="video.title"
          :class="{ 'form-control': true, 'is-invalid': errors.has('title') }"
          name="title"
          id="title"
          placeholder="Title"
          v-validate="'required'"
        />
        <div class="invalid-feedback">
          Please provide a valid name.
        </div>
      </div>

      <div class="form-group">
        <label for="meta_title">Meta Title (SEO):</label>
        <input
          type="text"
          v-model="video.meta_title"
          class="form-control"
          name="meta_title"
          id="meta_title"
          placeholder=""
        />
        <div class="invalid-feedback">
          Please provide a valid meta title.
        </div>
      </div>

      <div class="form-group">
        <label for="meta_description">Meta Description (SEO):</label>
        <textarea
          v-model="video.meta_description"
          class="form-control"
          name="meta_description"
          id="meta_description"
          placeholder=""
        ></textarea>
        <div class="invalid-feedback">
          Please provide a valid meta description.
        </div>
      </div>

      <div class="form-group">
        <label for="full_video_link">Full Video Link:</label>
        <input
          type="text"
          @blur="onFullVideoLinkChanged"
          v-model="video.full_video_link"
          :class="{
            'form-control': true,
            'is-invalid': errors.has('full_video_link')
          }"
          name="full_video_link"
          id="full_video_link"
          placeholder=""
          v-validate="'required'"
        />
        <div class="invalid-feedback">
          Please provide a valid full audio link.
        </div>
      </div>

      <div class="form-group">
        <label for="preview_video_link">Preview Video Link:</label>
        <input
          type="text"
          v-model="video.preview_video_link"
          :class="{
            'form-control': true,
            'is-invalid': errors.has('preview_video_link')
          }"
          name="preview_video_link"
          id="preview_video_link"
          placeholder=""
          v-validate="'required'"
        />
        <div class="invalid-feedback">
          Please provide a valid preview audio link.
        </div>
      </div>

      <fieldset class="form-group">
        <div class="row">
          <legend class="col-form-label col-sm-2 pt-0">
            Video Download Links (App)
          </legend>
          <div class="col-sm-10">
            <div class="row">
              <label for="blog_title">Low Quality 360p:</label>
              <input
                type="text"
                v-model="video.download_video_links['360p']"
                :class="{
                  'form-control': true,
                  'is-invalid': errors.has('download_video_low_quality')
                }"
                name="download_video_low_quality"
                id="download_video_low_quality"
                placeholder=""
                v-validate="'required'"
              />
              <div class="invalid-feedback">
                Please provide a valid video url for low quality 360p.
              </div>
            </div>
            <div class="row">
              <label for="blog_link">Good Quality 720p:</label>
              <input
                type="text"
                v-model="video.download_video_links['720p']"
                :class="{
                  'form-control': true,
                  'is-invalid': errors.has('download_video_good_quality')
                }"
                name="download_video_good_quality"
                id="download_video_good_quality"
                placeholder=""
                v-validate="'required'"
              />
              <div class="invalid-feedback">
                Please provide a valid video url for good quality 720p.
              </div>
            </div>
            <div class="row">
              <label for="excerpt">High Quality 1080p :</label>
              <input
                type="text"
                v-model="video.download_video_links['1080p']"
                :class="{
                  'form-control': true,
                  'is-invalid': errors.has('download_video_high_quality')
                }"
                name="download_video_high_quality"
                id="download_video_high_quality"
                placeholder=""
                v-validate="'required'"
              />
              <div class="invalid-feedback">
                Please provide a valid video url for high quality 1080p.
              </div>
            </div>
          </div>
        </div>
      </fieldset>
      f

      <div class="form-group">
        <label for="thumbnail_link">Thumbnail Link:</label>
        <input
          type="text"
          v-model="video.thumbnail_link"
          :class="{
            'form-control': true,
            'is-invalid': errors.has('thumbnail_link')
          }"
          name="thumbnail_link"
          id="thumbnail_link"
          placeholder=""
          v-validate="'required'"
        />
        <img :src="video.thumbnail_link" alt="Thumbnail Link" />
        <div v-show="video.full_video_link && video.full_video_link.length > 0">
          <button class="btn btn-primary" @click="uploadThumbnails">Upload Thumbnails</button>
        </div>

        <div class="invalid-feedback">
          Please provide a valid thumbnail link.
        </div>
      </div>

      <fieldset class="form-group">
        <div class="row">
          <legend class="col-form-label col-sm-2 pt-0">Blog Post</legend>
          <div class="col-sm-10">
            <div class="row">
              <label for="blog_title">Title:</label>
              <input
                type="text"
                v-model="video.blog_post.title"
                :class="{
                  'form-control': true,
                  'is-invalid': errors.has('blog_title')
                }"
                name="blog_title"
                id="blog_title"
                placeholder=""
                v-validate="'required'"
              />
              <div class="invalid-feedback">
                Please provide a valid blog title.
              </div>
            </div>

            <div class="row">
              <label for="blog_link">Link:</label>
              <input
                type="text"
                v-model="video.blog_post.link"
                :class="{
                  'form-control': true,
                  'is-invalid': errors.has('blog_link')
                }"
                name="blog_link"
                id="blog_link"
                placeholder=""
                v-validate="'required'"
              />
              <div class="invalid-feedback">
                Please provide a valid blog link.
              </div>
            </div>
            <div class="row">
              <label for="excerpt">Excerpt:</label>
              <textarea
                rows="6"
                cols="50"
                v-model="video.blog_post.excerpt"
                :class="{
                  'form-control': true,
                  'is-invalid': errors.has('excerpt')
                }"
                name="excerpt"
                id="excerpt"
                placeholder=""
                v-validate="'required'"
              >
              </textarea>
              <div class="invalid-feedback">
                Please provide a valid excerpt.
              </div>
            </div>
          </div>
        </div>
      </fieldset>

      <div class="form-group">
        <label for="description">Description:</label>
        <editor
          api-key="h6ymu878nq2dmit84wddqdm84to1x90pryg1iec6s96rph86"
          name="description"
          id="description"
          v-model="video.description"
          class="wysiwyg-editor"
          :init="{
            plugins:
              'wordcount link image fullscreen lists textcolor colorpicker a11ychecker advcode formatpainter linkchecker media mediaembed powerpaste tinydrive tinymcespellchecker'
          }"
          toolbar="fontselect | undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | fontsizeselect a11ycheck code formatpainter insertfile"
        ></editor>
        <div class="invalid-feedback">
          Please provide a valid description.
        </div>
      </div>

      <fieldset class="form-group">
        <div class="row">
          <legend class="col-form-label col-sm-2 pt-0">Workout Details</legend>
          <div class="col-sm-10">
            <div class="row">
              <label for="duration">Duration:</label>
              <input
                type="text"
                v-model="video.workout_details.duration"
                :class="{
                  'form-control': true,
                  'is-invalid': errors.has('duration')
                }"
                name="duration"
                id="duration"
                placeholder=""
                v-validate="'required'"
              />
              <div class="invalid-feedback">
                Please provide a duration.
              </div>
            </div>

            <div class="row">
              <label for="difficulty">Difficulty:</label>
              <input
                type="text"
                v-model="video.workout_details.difficulty"
                :class="{
                  'form-control': true,
                  'is-invalid': errors.has('difficulty')
                }"
                name="difficulty"
                id="difficulty"
                placeholder=""
                v-validate="'required'"
              />
              <div class="invalid-feedback">
                Please provide a difficulty.
              </div>
            </div>

            <div class="row">
              <label for="intensity">Intensity:</label>
              <input
                type="text"
                v-model="video.workout_details.intensity"
                :class="{
                  'form-control': true,
                  'is-invalid': errors.has('intensity')
                }"
                name="intensity"
                id="intensity"
                placeholder=""
                v-validate="'required'"
              />
              <div class="invalid-feedback">
                Please provide a intensity.
              </div>
            </div>

            <div class="row">
              <label for="training_focus">Training Focus:</label>
              <input
                type="text"
                v-model="video.workout_details.training_focus"
                :class="{
                  'form-control': true,
                  'is-invalid': errors.has('training_focus')
                }"
                name="training_focus"
                id="training_focus"
                placeholder=""
                v-validate="'required'"
              />
              <div class="invalid-feedback">
                Please provide a training focus.
              </div>
            </div>

            <div class="row">
              <label for="workout_type">Workout Type:</label>
              <input
                type="text"
                v-model="video.workout_details.type"
                :class="{
                  'form-control': true,
                  'is-invalid': errors.has('workout_type')
                }"
                name="workout_type"
                id="workout_type"
                placeholder=""
                v-validate="'required'"
              />
              <div class="invalid-feedback">
                Please provide a workout type.
              </div>
            </div>

            <div class="row">
              <label for="water_depth">Water Depth:</label>
              <input
                type="text"
                v-model="video.workout_details.water_depth"
                :class="{
                  'form-control': true,
                  'is-invalid': errors.has('water_depth')
                }"
                name="water_depth"
                id="water_depth"
                placeholder=""
                v-validate="'required'"
              />
              <div class="invalid-feedback">
                Please provide a water depth.
              </div>
            </div>

            <div class="row">
              <label for="equipment">Equipment:</label>
              <input
                type="text"
                v-model="video.workout_details.equipment"
                :class="{
                  'form-control': true,
                  'is-invalid': errors.has('equipment')
                }"
                name="equipment"
                id="equipment"
                placeholder=""
                v-validate="'required'"
              />
              <div class="invalid-feedback">
                Please provide equipment.
              </div>
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset class="form-group">
        <div class="row">
          <legend class="col-form-label col-sm-2 pt-0">Featured Content</legend>
          <div class="col-sm-10">
            <div class="row">
              <label for="featured_title">Title:</label>
              <input
                type="text"
                v-model="video.featured_content.title"
                :class="{
                  'form-control': true,
                  'is-invalid': errors.has('featured_title')
                }"
                name="featured_title"
                id="featured_title"
                placeholder=""
                v-validate="'required'"
              />
              <div class="invalid-feedback">
                Please provide a valid featured content title.
              </div>
            </div>
            <div class="row">
              <label for="featured_media_type">Media Type:</label>
              <div class="form-check">
                <input
                  type="radio"
                  class="form-check-input"
                  name="featured_media_type"
                  id="featured_media_type_video"
                  value="video"
                  v-model="video.featured_content.type"
                />
                <label for="featured_media_type_video" class="form-check-label"
                  >Video</label
                >
              </div>
              <div class="form-check" style="margin-left: 15px">
                <input
                  type="radio"
                  class="form-check-input"
                  name="featured_media_type"
                  id="featured_media_type_image"
                  value="image"
                  v-model="video.featured_content.type"
                />
                <label for="featured_media_type_image" class="form-check-label"
                  >Image</label
                >
              </div>
            </div>
            <div class="row">
              <label for="featured_media_uri">Media Link:</label>
              <input
                type="text"
                v-model="video.featured_content.media_uri"
                :class="{
                  'form-control': true,
                  'is-invalid': errors.has('featured_media_uri')
                }"
                name="featured_media_uri"
                id="featured_media_uri"
                placeholder=""
                v-validate="'required'"
              />
              <div class="invalid-feedback">
                Please provide a valid media link.
              </div>
            </div>
            <div class="row">
              <label for="featured_link">Link:</label>
              <input
                type="text"
                v-model="video.featured_content.link"
                :class="{
                  'form-control': true,
                  'is-invalid': errors.has('featured_link')
                }"
                name="featured_link"
                id="featured_link"
                placeholder=""
                v-validate="'required'"
              />
              <div class="invalid-feedback">
                Please provide a valid redirect link.
              </div>
            </div>
            <div class="row">
              <label for="description">Description:</label>
              <textarea
                rows="6"
                cols="50"
                v-model="video.featured_content.description"
                :class="{
                  'form-control': true,
                  'is-invalid': errors.has('featured_media_description')
                }"
                name="featured_media_description"
                id="featured_media_description"
                placeholder=""
                v-validate="'required'"
              >
              </textarea>
              <div class="invalid-feedback">
                Please provide a valid description.
              </div>
            </div>
          </div>
        </div>
      </fieldset>

      <div class="form-group">
        <label for="release_date">Release Date:</label>
        <datepicker
          placeholder="Select Date"
          v-model="video.release_date"
          name="uniquename"
          input-class="form-control"
        ></datepicker>
      </div>

      <div class="form-group signup-carousel-buttons">
        <button class="btn btn-primary">Save</button>
      </div>
    </form>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import Editor from "@tinymce/tinymce-vue";
import ThumbnailService from "../services/ThumbnailService";


export default {
  methods: {
    saveChanges: function() {
      let token = this.$cookies.get("poolfit_admin_session");

      if (token !== null) {
        let options = {
          headers: { "X-Access-Token": token }
        };
        if (!isNaN(this.$route.params.id)) {
          this.$http
            .patch(
              process.env.VUE_APP_ROOT_API + "/videos/" + this.$route.params.id,
              this.video,
              options
            )
            .then(response => {
              if (response.status === 200) {
                alert("Record updated successfully");
              }
            });
        } else if (this.$route.params.id == "add") {
          this.$http
            .post(process.env.VUE_APP_ROOT_API + "/videos", this.video, options)
            .then(response => {
              if (response.status === 200) {
                alert("Record created successfully");
              }
            });
        }
      }
    },

    uploadThumbnails: function() {
      ThumbnailService.uploadThumbnailsToS3ForVimeoEmbedLink(this.video.full_video_link).then(response => {
        alert("Successfully uploaded thumbnails");
      }).catch(err => {
        alert("An error occurred");
      });
      
    },

    onFullVideoLinkChanged: function() {
      if (this.video.full_video_link.length > 0) {
        var vimeoVideoId = this.video.full_video_link.split("/").pop();

        if (vimeoVideoId) {
          this.$http
            .get(
              "https://vimeo.com/api/oembed.json?url=http%3A//vimeo.com/" +
                vimeoVideoId
            )
            .then(response => {
              this.axios
                .get(
                  "https://vimeo.com/api/oembed.json?url=http%3A//vimeo.com/" +
                  vimeoVideoId
                )
                .then((response) => {
                  let thumbnailLink = response.data.thumbnail_url.replace('.webp', '').split('_');

                  let thumbnailLinkLowRes = thumbnailLink[0] + '_300.jpg';

                  this.video.thumbnail_link = 'https://dj2egt95vsmn2.cloudfront.net/thumbnails/' + thumbnailLinkLowRes.replace('.jpg', '').split('/').reverse()[0];

                });
            });
        }
      }
    }
  },

  data: function() {
    return {
      isLoaded: false,
      instructors: [],
      video: {
        thumbnail_link: null,
        full_video_link: "",
        blog_post: {},
        workout_details: {},
        featured_content: {},
        download_video_links: {}
      }
    };
  },

  components: {
    datepicker: Datepicker,
    editor: Editor
  },

  created: function() {
    if (!isNaN(this.$route.params.id)) {
      let token = this.$cookies.get("poolfit_admin_session");
      if (token !== null) {
        let options = {
          headers: { "X-Access-Token": token }
        };

        this.$http
          .get(
            process.env.VUE_APP_ROOT_API + "/videos/" + this.$route.params.id,
            options
          )
          .then(response => {
            if (response.data.featured_content === null) {
              response.data.featured_content = {};
            }
            if (response.data.download_video_links === null) {
              response.data.download_video_links = {};
            }

            this.$http
              .get(process.env.VUE_APP_ROOT_API + "/instructors")
              .then(instructorsResponse => {
                this.instructors = instructorsResponse.data;
                this.isLoaded = true;
              });

            this.video = response.data;
          });
      }
    } else if (this.$route.params.id !== "add") {
      alert("Invalid parameter.");
    } else {
      this.$http
              .get(process.env.VUE_APP_ROOT_API + "/instructors")
              .then(instructorsResponse => {
                this.instructors = instructorsResponse.data;
                this.isLoaded = true;
              });
    }
  }
};
</script>
<style></style>
